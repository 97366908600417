<template>
  <div>
    <div
      class="newpdp-popup"
    >
      <h5
        v-text="getProductText"
      />
      <div class="newpdp-popup-wrapper">
        <!-- brand is missing -->
        <ProductDetails
          class="pdp-box"
          :product="productWithCategoryDesigns"
          :brand="brand"
          :download-files="productDownloads"
          :product-name="productDescription"
          @open-variants="this.showVariants = true"
        />
        <!-- :loading currently always false, till warehouse switch is implemented and the component can trigger reload -->
        <AvailabilityPricing
          :product="buyboxProduct"
          :loading="false"
          @addToCart="handleAddToCart"
        />
      </div>
    </div>
    <PopupSuccessorVariants
      :show="showVariants"
      :sku-array="variantsAndSuccessor"
      @close-variants="showVariants = false"
      :second-layer="true"
    />
  </div>
</template>

<script>
import {usePurchaseService} from '@/composables/purchase_service'
import {
  FILENAME_REGEX,
  POPUP,
  PROPERTY_SCOPE_NAMES,
  ASSET_TYPES,
  ADDITEM_STATUS
} from '~/assets/js/constants'
import {storeToRefs} from 'pinia'
import {getNextDate} from '~/assets/js/utils'
import availabilitiesMixin from '~/mixins/availabilities'
import {useCartStore} from '@/store/cart'
import {useCustomerSettingsStore} from '@/store/customer-settings'
import {useUserStore} from '@/store/user'
import ProductDetails from '~/components/product-detail/bb_components/ProductDetails'
import AvailabilityPricing from '~/components/product-detail/bb_components/AvailabilityPricing'
import {
  getMappedProduct,
  getMappedProductCategoryImages
} from '~/assets/js/utils/product_attributes_mapping'
import {getEComText} from '~/assets/js/utils/product'
import {useProductService} from '@/composables/product_service'

export default {
  components: {
    ProductDetails,
    AvailabilityPricing
  },
  mixins: [availabilitiesMixin],
  name: 'ProductBuyBox',
  props: {
    productData: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  setup() {
    const {currentCustomerSettings} = storeToRefs(useCustomerSettingsStore())
    const {deliveryAddressUuid} = storeToRefs(useCartStore())
    const {loadedCustomerUuid} = storeToRefs(useUserStore())
    const {addItems} = useCartStore()
    const {getProductQuantityAdjustments, getProductQuantityRounding} =
      usePurchaseService()

    const {getProductBySkuNew} = useProductService()

    return {
      currentCustomerSettings,
      deliveryAddressUuid,
      loadedCustomerUuid,
      addItems,
      getProductQuantityAdjustments,
      getProductQuantityRounding,
      getProductBySkuNew
    }
  },
  data() {
    return {
      adjustments: {
        adjustments: null,
        loading: false,
        lastRequestedQuantity: -1
      },
      quantityChosen: 1,
      availabilityDate: getNextDate(),
      warehouse: null,
      showAvailableWarehousesModal: false,
      price: null,
      availability: {},
      inProgress: false,
      priceScales: [],
      priceScalesLoading: false,
      changeTimeoutHandle: null,
      changeStateTimeoutHandle: null,
      warningsAndErrors: null,
      firstQuantityRoundingDone: false,
      showPriceScalesTooltip: false,
      trackedHotjar: false,
      showVariants: false,
      transitionEnter: 400,
      transitionExit: 200,
      popupSizeMedium: POPUP.Size.medium,
      product: null
    }
  },
  mounted() {
    this.product = this.productData
    this.$eventbus.on('emit-warehouse-change', async (alternativePlantCode) => {
      const newProductData = await this.getProductBySkuNew(this.product.sku, this.$globalization.getRegion(), this.$i18n.locale, this.showInchValues, alternativePlantCode, PROPERTY_SCOPE_NAMES.PRODUCT_PAGE_NEW)
      // eslint-disable-next-line vue/no-mutating-props
      this.product = newProductData
    })
  },
  unmounted() {
    this.$eventbus.off('emit-warehouse-change')
  },
  methods: {
    async handleAddToCart(payload) {
      const markedAsRequest = payload?.markedAsRequest ?? false
      const quantity = payload?.quantity ?? 1
      const availabilityDate = payload?.availabilityDate ?? null
      const warehouse = payload?.warehouse ?? null

      if (
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_BUY) &&
        !this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_REQUEST)
      ) {
        this.$toast.error(this.$tv('failed', 'cart'))
      }

      if (this.changeTimeoutHandle) {
        clearTimeout(this.changeTimeoutHandle)
      }
      this.inProgress = true

      try {
        let item = {
          sku: this.product?.sku,
          quantity,
          availabilityDate
        }

        if (markedAsRequest) {
          item.markedAsRequest = markedAsRequest
        }

        if (
          warehouse &&
            !warehouse.isDefault &&
            warehouse.alternativePlantGuid
        ) {
          item.alternativePlantUuid = warehouse.alternativePlantGuid
        }

        let res = await this.addItems({
          itemsToAdd: [item],
          region: this.$globalization.getRegion(),
          locale: this.$i18n.locale
        })
        if (res && res.length > 0 && res[0].status === ADDITEM_STATUS.SUCCESS) {
          this.$toast.success(
            useToastificationHelper().translateAndFormat(
              {
                messageKey: markedAsRequest
                  ? 'itemAddedWithQtyRequest'
                  : 'itemAddedWithQtyBuy',
                messageValues: {
                  sku: this.product?.sku,
                  quantity: res[0]?.addedQty
                }
              },
              'cart'
            )
          )
          this.$emit('productSuccessfullyAdded')
        } else {
          this.inProgress = false
          if (res && res.length > 0 && res[0].errors.length > 0 && !res[0].errors.some(e => e.messageKey === 'Cart_Max_Items_Exceeded')) {
            this.$toast.error(this.$tv('failed', 'cart'))
          }
          return
        }
      } catch {
        this.$toast.error(this.$tv('failed', 'cart'))
      }

      this.inProgress = false
    },
    getSortOrder(assetType) {
      if (assetType === 'CAD') {
        return 1
      }
      return 0
    }
  },
  computed: {
    getProductText() {
      const lang = this.$i18n.locale.toUpperCase()
      const unit = this.showInchValues ? 'Imperial' : 'Metric'
      return this.product['locales_' + lang + '_' + unit + '_SalesTextECom'] ?? getEComText(this.$tv, this.product)
    },
    productDescription: function () {
      return this.product?.locales[this.$i18n.locale] ?? ''
    },
    buyboxProduct() {
      return getMappedProduct(this.product)
    },
    productWithCategoryDesigns: function () {
      return getMappedProductCategoryImages(this.product, null, false)
    },
    productDownloads() {
      let result = []
      let categoryAssets = []
      if (this.categoryContents?.locale === this.$i18n.locale) {
        if (this.categoryContents?.Assets.length > 0) {
          categoryAssets = this.categoryContents.Assets.map((c) => ({
            assetType: c.AssetType,
            lookupKey: c.AssetURI,
            languageShortCode: this.$i18n.locale,
            sortOrder: 1
          }))
        } else if (this.categoryContents?.Downloads.length > 0) {
          categoryAssets = this.categoryContents.Downloads.map((d) => ({
            assetType: d.AssetType,
            lookupKey: d.File.url,
            languageShortCode: this.$i18n.locale,
            label:
              d.AssetType === ASSET_TYPES.ProductDataSheet
                ? this.$tv('ProductDataSheet', 'product.assetLink', d.Label)
                : d.Label,
            sortOrder: 1
          }))
        }
      }

      if (this.product?.assets) {
        result = this.product.assets.filter(
          (asset) => asset.languageShortCode === this.$i18n.locale
        )
        let presentAssetTypes = result.map((asset) => asset.assetType)
        result = result.concat(
          this.product.assets.filter(
            (asset) =>
              asset.languageShortCode === this.$i18n.defaultLocale &&
              !presentAssetTypes.includes(asset.assetType)
          )
        )
        if (
          categoryAssets.some(
            (asset) =>
              asset.assetType &&
              asset.assetType.toLowerCase() ===
                ASSET_TYPES.ProductDataSheet.toLowerCase()
          )
        ) {
          result = result.filter(
            (asset) =>
              !asset.assetType ||
              asset?.assetType?.toLowerCase() !== 'productdatasheet'
          )
        }
        result = result.map((asset) => ({
          ...asset,
          // alt text is to be set to the filename to identify materials in the download section, the filename is extracted from the lookupKey (#28924)
          altText:
            asset.label ||
            (asset.lookupKey &&
            FILENAME_REGEX.test(asset.lookupKey) &&
            asset.lookupKey.match(FILENAME_REGEX)?.groups?.filename
              ? decodeURI(
                asset.lookupKey.match(FILENAME_REGEX)?.groups?.filename
              )
              : null),
          sortOrder: this.getSortOrder(asset.assetType)
        }))
      }
      result = result.concat(categoryAssets)
      return result.length > 0
        ? result.sort((a, b) => a.sortOrder - b.sortOrder)
        : result
    },
    variantsAndSuccessor() {
      const variants = (this.$deproxy(this.product?.otherVariants) ?? []).map(v => ({...v, isSuccessor: false}))
      const successor = this.product?.salesAreas.map(s => {
        // eslint-disable-next-line eqeqeq
        return s.successorSku != null ? {sku: s.successorSku, isSuccessor: true} : null
      })
      let result = null
      if (successor && variants) {
        result = [{sku: this.product?.sku}, ...successor, ...variants]
      }
      else if (successor) {
        result = [{sku: this.product?.sku}, ...successor]
      }
      else if (variants) {
        result = [{sku: this.product?.sku}, ...variants]
      }
      else {
        result = [{sku: this.product?.sku}]
      }
      // eslint-disable-next-line eqeqeq
      return result.filter(vs => vs != null)
    }
  },
  watch: {
    productData: {
      immediate: true,
      handler() {
        this.product = this.productData
      }
    }
  }
}
</script>

<style lang="scss">
.popup__wrapper {
  width: fit-content;
  overflow: auto;

  .popup__content a {
    z-index: 2001;
  }
}

.popup__content:has(.buy-box-in-popup) {
  padding-top: 1rem;

  .pdp-box {
    box-shadow: $buybox-figma-boxshadow;

    .gallery-list {
      display: none;
    }
  }
}

.popup {
  .newpdp-popup {
    background: $color__white;
    padding: 0;

    h5 {
      margin: 0 0 1rem;
    }

    &__slide__variants {
      display: none;
    }

    .showSlide {
      display: block;
    }

    .newpdp-popup-wrapper {
      display: flex;
      gap: 1rem;
    }
  }

  @include breakpoint-down($lg) {
    &.popup__buy-box .popup__wrapper {
      max-width: 25rem;
    }

    &.popup__buy-box-new .popup__wrapper {
      max-width: 39rem;

      .newpdp-popup-wrapper {
        flex-direction: column;

        .successor-variants {
          flex-basis: 100%;
        }

        .product-details-component {
          width: 100%;
        }

        [id='newProductBuyBox'] {
          width: calc(100% - 2rem);
        }
      }
    }
  }

  @include breakpoint-down($md) {
    &.popup__buy-box-new .popup__wrapper .newpdp-popup > * > * {
      margin: 2.5rem 0;
    }
  }

  &.popup__buy-box-new .popup__wrapper {
    overflow: hidden;
  }

  @include breakpoint-up($lg) {
    &.popup__buy-box-new .popup__wrapper {
      max-width: 78rem;

      .popup-successorVariants {
        .popup .popup__wrapper {
          width: auto;
          max-width: 58rem;
        }
      }

      .newpdp-popup {
        &__slide__variants {
          min-width: max-content;
        }

        > * > * {
          flex-direction: row;
        }

        .product-details-wrapper {
          width: calc(50% - .5rem);
        }

        .newpdp-popup-wrapper {
          display: flex;
          gap: 1rem;

          .product-details-wrapper,
          [id='newProductBuyBox'] {
            flex: 1;
            min-width: 0;
          }
        }
      }
    }
  }

  &.popup__buy-box-new .popup__wrapper & :not(.second-layer) {
    .popup__content {
      padding: 0;
    }
  }

  [id='ProductBuyBox'] {
    padding: 0;
  }
}
</style>
